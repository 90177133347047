<template>
  <CBox v-if="isLoadingPage"></CBox>
  <CBox
    v-else-if="
      currentClubPost !== 0 && currentUser.emp_id === currentClubPost.emp_id
    "
    d="flex"
    justifyContent="space-between"
    flexWrap="wrap"
  >
    <CBox
      borderRadius="10px"
      mt="5"
      :w="['100%', '100%', '74%', '74%']"
      bg="white"
      shadow="sm"
      rounded="md"
      py="24px"
      :px="['10px', '24px', '24px', '24px']"
      position="relative"
    >
      <ImageCroppie
        :isModalOpen="isModalOpen"
        :urlImage="imagePreview"
        @closeModal="closeModal"
        @cropCompleted="handleCropCompleted"
        @cropBlob="handleCropBlob"
      />
      <CBox>
        <CText fontStyle="extrabold" fontSize="3xl" fontWeight="semibold"
          >Edit Post</CText
        >
        <form method="post">
          <CBox px="4">
            <CBox
              d="flex"
              justifyContent="space-between"
              :alignItems="['start', 'center', 'center', 'center']"
              :flexDirection="['column', 'row', 'row', 'row']"
            >
              <CBox d="flex" alignItems="center" py="16px">
                <CImage
                  :src="
                    currentClub.logo
                      ? currentClub.logo
                      : require('@/assets/img/default-profile.png')
                  "
                  borderRadius="5"
                  w="50px"
                  h="50px"
                  mr="8px"
                />
                <CText fontWeight="600" fontSize="25px">{{
                  currentClub.name
                }}</CText>
              </CBox>
              <CBox d="flex" alignItems="center" ml="auto">
                <CButton
                  bg="white"
                  color="danger"
                  fontWeight="600"
                  fontSize="12px"
                  :_hover="{ opacity: '0.8' }"
                  size="0"
                  px="16px"
                  py="8px"
                  mr="6px"
                  @click.native="backToPrevPage"
                  >Discard</CButton
                >
                <CButton
                  bg="bgButtonClub"
                  color="white"
                  fontWeight="600"
                  fontSize="12px"
                  :_hover="{ opacity: '0.8' }"
                  size="0"
                  px="16px"
                  py="8px"
                  @click.native="putData"
                  :disabled="isCreateClubButtonDisabled || isLoading"
                  >{{ !isLoading ? "Update" : "Loading..." }}</CButton
                >
              </CBox>
            </CBox>
            <CFormControl mb="4">
              <CFormLabel for="name">Title</CFormLabel>
              <CInput id="name" v-model="params.title" required />
              <CFormHelperText
                :color="params.title.length > 50 ? '#E82609' : '#ACACAC'"
                maxW="300px"
                fontSize="12px"
              >
                {{
                  params.title.length > 50
                    ? "Title too long."
                    : "Maximum 50 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl mb="4">
              <CFormLabel for="description">Caption</CFormLabel>
              <textarea
                id="description"
                v-model="params.description"
                rows="4"
                class="textarea-club"
                required
              ></textarea>
              <CFormHelperText
                :color="params.description.length > 500 ? '#E82609' : '#ACACAC'"
                maxW="300px"
                fontSize="12px"
              >
                {{
                  params.description.length > 500
                    ? "Caption too long."
                    : "Maximum 500 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl my="4">
              <CInputGroup>
                <CInput
                  type="file"
                  accept="image/*"
                  @change.native="handleImageUpload"
                  required
                  d="none"
                  ref="fileInput"
                  id="fileInput"
                />
                <CBox w="full">
                  <CFormLabel
                    for="fileInput"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    position="relative"
                    w="full"
                    p="0"
                    @dragover.prevent
                    @drop="handleImageDrop"
                    :class="{ 'drag-over': isDragging }"
                  >
                    <CBox
                      d="flex"
                      justifyContent="center"
                      alignItems="center"
                      border="1px dashed #ACACAC"
                      w="100%"
                      h="100px"
                      borderRadius="5px"
                    >
                      <CImage
                        :src="require('@/assets/icon/icon-empty-file.svg')"
                        d="inline-block"
                        mr="10px"
                      />
                      <CText color="#ACACAC" d="inline-block" fontSize="12px"
                        >Drag and drop photos or click to upload</CText
                      >
                    </CBox>
                  </CFormLabel>
                  <CFormHelperText fontSize="12px" color="#ACACAC">
                    Maximum file size: 10Mb and Format file .jpg, .jpeg, and
                    .png
                  </CFormHelperText>
                  <CFormHelperText
                    v-if="errorMessage"
                    color="red.300"
                    fontSize="12px"
                    my="auto"
                  >
                    {{ errorMessage }}
                  </CFormHelperText>
                  <CFormHelperText
                    v-else-if="fileSize > 3200000"
                    color="red.300"
                    fontSize="12px"
                    my="auto"
                  >
                    File size too large
                  </CFormHelperText>
                  <CBox d="flex" justifyContent="center" my="16px">
                    <CBox v-if="photos" d="inline-block">
                      <CBox
                        v-for="(v, i) in photos"
                        :key="i"
                        w="100px"
                        h="100px"
                        position="relative"
                        mr="8px"
                        d="inline-block"
                      >
                        <CImage
                          :src="v"
                          borderRadius="5"
                          w="full"
                          mx="4px"
                          :_hover="{ opacity: '0.8' }"
                        />
                        <CImage
                          :src="require('@/assets/icon/icon-close-red.svg')"
                          w="20px"
                          bg="white"
                          borderRadius="full"
                          mt="5px"
                          position="absolute"
                          top="0"
                          right="0"
                          cursor="pointer"
                          :_hover="{ opacity: '0.8' }"
                          @click.native="removeImagePhotos(i)"
                        />
                      </CBox>
                    </CBox>
                    <CBox v-if="croppedImageData" d="inline-block">
                      <CBox
                        v-for="(v, i) in croppedImageData"
                        :key="i"
                        w="100px"
                        h="100px"
                        position="relative"
                        mr="8px"
                        d="inline-block"
                      >
                        <CImage
                          :src="v"
                          borderRadius="5"
                          w="full"
                          mx="4px"
                          :_hover="{ opacity: '0.8' }"
                        />
                        <CImage
                          :src="require('@/assets/icon/icon-close-red.svg')"
                          w="20px"
                          bg="white"
                          borderRadius="full"
                          mt="5px"
                          position="absolute"
                          top="0"
                          right="0"
                          cursor="pointer"
                          :_hover="{ opacity: '0.8' }"
                          @click.native="removeImage(i)"
                        />
                      </CBox>
                    </CBox>
                  </CBox>
                </CBox>
              </CInputGroup>
            </CFormControl>
          </CBox>
        </form>
      </CBox>
    </CBox>
    <CBox
      v-if="!isLoadingPage && errorMessage !== 'club not found'"
      :w="['100%', '100%', '25%', '25%']"
    >
      <SidebarDetailClub />
    </CBox>
  </CBox>
</template>

<script>
import {
  CBox,
  CText,
  CFormControl,
  CFormLabel,
  CInput,
  CButton,
  CFormHelperText,
  CInputGroup,
  CImage,
} from "@chakra-ui/vue";
import Swal from "sweetalert2";
import {
  POST_CLUB_IMAGE,
  GET_CLUB_DETAIL,
  PUT_CLUB_POST,
  GET_CLUB_POST_DETAIL,
} from "@/store/club.module";
import SidebarDetailClub from "@/components/sidebar/DetailClub.vue";
import ImageCroppie from "@/components/modal/ImageCroppie.vue";
import config from "@/ConfigProvider.js";

export default {
  name: "EditPostClub",
  components: {
    SidebarDetailClub,
    CBox,
    CText,
    CFormControl,
    CFormLabel,
    CInput,
    CButton,
    CFormHelperText,
    CInputGroup,
    ImageCroppie,
    CImage,
  },
  metaInfo() {
    return {
      title: "Edit Post",
      titleTemplate: "Devcode - %s",
    };
  },
  data() {
    return {
      isLoading: false,
      isDragging: false,
      isLoadingPage: true,
      imagePreview: "",
      fileSize: 0,
      params: {
        photos: [],
        title: "",
        description: "",
      },
      photos: [],
      errorMessage: "",
      cropperOptions: 2,
      isModalOpen: false,
      isUrlCopied: false,
      croppedImageData: [],
      croppedImageBlob: [],
      urlGethired: config.value("GETHIRED_WEB_URL"),
    };
  },
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  methods: {
    async putData() {
      try {
        this.isLoading = true;

        this.params.photos = [];

        if (this.photos && this.photos.length > 0) {
          for (const value of this.photos) {
            this.params.photos.push(value);
          }
        }

        if (this.croppedImageBlob && this.croppedImageBlob.length > 0) {
          for (const croppedImage of this.croppedImageBlob) {
            let imageResponse = await this.$store.dispatch(
              POST_CLUB_IMAGE,
              croppedImage
            );
            this.params.photos.push(imageResponse.data.location);
          }
        }

        const params = {
          id: this.$route.params.id,
          data: {
            ...this.params,
          },
        };

        if (this.currentUser) {
          await this.$store.dispatch(PUT_CLUB_POST, params);
          Swal.fire({
            title: "Updated!",
            text: "Club post updated successfully!",
            icon: "success",
          }).then(() => {
            this.$router
              .push("/clubs/" + this.currentSlug + "/posts")
              .then(() => {
                setTimeout(() => {
                  window.location.reload(true);
                }, 0);
              });
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage = error.response.data.message;
          console.error(`Error 400: ${this.errorMessage}`);
        } else {
          console.error("Unexpected error:", error.message);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getData() {
      try {
        await this.$store.dispatch(GET_CLUB_DETAIL, {
          slug: this.$route.params.slug,
        });
        if (
          this.currentClub.is_private === true &&
          this.currentClub.is_joined === 0
        ) {
          Swal.fire({
            title: "Oops!",
            text: "You don't have access to this page!",
            icon: "error",
          }).then(() => {
            window.history.back();
          });
        } else {
          await this.$store.dispatch(GET_CLUB_POST_DETAIL, {
            id: this.$route.params.slug,
            postId: this.$route.params.id,
          });

          this.params = {
            title: this.currentClubPost.title,
            description: this.currentClubPost.description,
          };
          this.photos = this.currentClubPost.photos;
          if (this.currentUser.emp_id !== this.currentClubPost.emp_id) {
            Swal.fire({
              title: "Oops!",
              text: "You don't have access to this page!",
              icon: "error",
            }).then(() => {
              window.history.back();
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.errorMessage = error.response.data.message;
          console.error(`Error 400: ${this.errorMessage}`);
          Swal.fire({
            title: "Oops!",
            text: "You don't have access to this page!",
            icon: "error",
          }).then(() => {
            window.history.back();
          });
        }
      } finally {
        this.isLoadingPage = false;
      }
    },
    removeImage(index) {
      this.croppedImageData.splice(index, 1);

      if (this.croppedImageBlob && this.croppedImageBlob.length > index) {
        this.croppedImageBlob.splice(index, 1);
      }
    },
    removeImagePhotos(index) {
      this.photos.splice(index, 1);
    },
    backToPrevPage() {
      window.history.back();
    },
    isFormComplete() {
      return (
        this.params.title &&
        this.params.title?.length <= 50 &&
        this.params.description &&
        this.params.description?.length <= 500
      );
    },
    async handleImageUpload(event) {
      const file = event.target.files[0];

      if (
        this.croppedImageData &&
        this.photos &&
        this.croppedImageData.length + this.photos.length > 2
      ) {
        this.errorMessage = "Exceeded the limit for image uploads.";
      } else if (file && file.size && file.size <= 3200000) {
        this.fileSize = file.size;

        // Simpan URL gambar sebagai string
        this.imagePreview = URL.createObjectURL(file);

        const formData = new FormData();
        formData.append("file", file);

        this.isModalOpen = true;
      } else if (file.size > 3200000) {
        this.fileSize = file.size;
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleCropCompleted(croppedData) {
      this.croppedImageData.push(croppedData);
    },
    async handleCropBlob(croppedImageBlob) {
      this.croppedImageBlob.push(croppedImageBlob);
    },
    handleImageDrop(event) {
      this.isDragging = false;
      event.preventDefault();
      this.fileSize = 0;
      this.errorMessage = "";

      const file = event.dataTransfer.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.imagePreview = reader.result;
      };

      reader.readAsDataURL(file);
      if (
        this.croppedImageData &&
        this.photos &&
        this.croppedImageData.length + this.photos.length > 2
      ) {
        this.errorMessage = "Exceeded the limit for image uploads.";
      } else if (
        (file.type.includes("image/png") && file.size <= 3200000) ||
        (file.type.includes("image/jpeg") && file.size <= 3200000)
      ) {
        this.isModalOpen = true;
      } else {
        this.errorMessage = "Format file not supported.";
        this.fileSize = file.size;
      }
    },
    hideAlert() {
      this.showAlert = false;
    },
  },
  computed: {
    currentClub() {
      return this.$store.getters.currentClub || [];
    },
    currentClubPost() {
      return this.$store.getters.currentClubPost || 0;
    },
    currentSlug() {
      return this.$route.params.slug;
    },
    isCreateClubButtonDisabled() {
      return !this.isFormComplete();
    },
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    formattedLink: {
      get() {
        return this.params.link.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
      },
      set(value) {
        this.$set(
          this.params,
          "link",
          value.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase()
        );
      },
    },
  },
};
</script>

<style scoped>
.drag-over {
  border-color: #3085d6;
}
</style>
